.App {
  text-align: center;
}

body {
  background-color: #4991B1;
}

.App-header {
  background-color: #357D9D;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.appBody {
  padding: 30px;
  flex-direction: row;
  display: flex;
  gap: 30px;
}

.tableContainer {
  flex-grow: 2;
  min-height: calc(100vh - 140px);
}

.tableContainer:first-child {
  flex-grow: 3;
}

.dataTable {
  background-color: #fff;
}

.MuiDataGrid-footerContainer {
  display: none !important;
}

.fieldWrapper {
  padding: 30px 0px;
}

.optionContainer {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.putBack {
  background-color: #357D9D;
}

.instructions {
  width: 350px;
  display: flex;
}

.picksContainer {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.dragMessage {
  color: #155D7D;
  font-size: 16px;
  font-weight: 800;
}

.cardAvatar {
  cursor: pointer;
}

.currentPick:hover .plusButton {
  display: none;
}

.currentPick:not(:hover) .minusButton, .availablePick .minusButton {
  display: none;
}

.currentPick .cardAvatar:hover {
  background-color: darkred;
}

.availablePick .cardAvatar:hover {
  background-color: green;
}

.invisibleMarkup {
  opacity: 0;
  margin-right: -44px;
}

.preSlot {
  height: 34px;
  margin-top: -27px;
  margin-bottom: -16px;
}

.preSlotLine {
  height: 4px;
  width: 100%;
  margin-top: 20px;
}

.closeButton {
  display: inline !important;
  color: #000 !important;
  margin-left: -18px !important;
  margin-right: -34px !important;
  margin-bottom: -6px !important;
  margin-top: -7px !important;
}

.lastUpdate {
  font-size: 16px;
  margin-left: 8px;
  margin-top: 5px;
}

@media only screen and (max-device-width: 480px) {
  .appBody {
    flex-direction: column;
    padding: 10px;
    padding-bottom: 30px;
  }

  .tableContainer {
    margin-bottom: 30px;
  }

  .App-header {
    display: block;
  }

  .lastUpdate {
    display: block;
  }
}
